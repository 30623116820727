const routes = [
  {
    startsWith: ['/invoices', '/templates', '/documents', '/dashboard'],
    app: 'documents',
    isProtected: true,
  },
  {
    equals: ['/', '/icici'],
    app: 'docucentralLanding',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/login',
    name: 'loginRoutingApp',
    app: 'login',
    customProps: {},
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/signup', '/update-password'],
    app: 'authV2',
    hideHeader: true,
    customProps: {},
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/forgot-password', '/change-password'],
    app: 'changePassword',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/account',
    app: 'customers',
    isProtected: true,
  },
  {
    startsWith: '/regularization',
    app: 'regularization',
    isProtected: true,
  },
  {
    startsWith: '/regularization-form',
    app: 'regularizationForm',
    isProtected: true,
  },
  {
    startsWith: '/regularize',
    app: 'regularization',
    isProtected: false,
  },
  {
    startsWith: '/shipments',
    app: 'shipments',
    isProtected: true,
  },
  {
    startsWith: '/shipments/v2',
    app: 'shipmentsV2',
    isProtected: true,
  },
  {
    startsWith: '/contracts',
    app: 'contracts',
    isProtected: true,
  },
  {
    startsWith: '/insights',
    app: 'reports',
    isProtected: true,
  },
];

export default routes;